import React from "react";
import backgroundImage from "../Assets/backgroun-about-us.jpg"; // Replace with your image path
import founderImage from "../Assets/founder.jpg"; // Replace with founder's image path
import farhanImage from "../Assets/farhan.png";
import harisImage from "../Assets/haris.jpg";
import hasanImage from "../Assets/hasan.jpg";
import anserImage from "../Assets/anser.jpg";
import abImage from "../Assets/abdullah.jpg";
import abdulRehmanImage from "../Assets/andulRehman.png";
import ahmadakbarImage from "../Assets/ahmadali.png";
import { Card, Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Define an array of team members
const teamMembers = [
  {
    name: "Hasan Talat",
    designation: "Chief Executive Officer",
    image: founderImage, // Replace with the image URL for team member 1
  },

  {
    name: "M. Farhan Ashraf",
    designation: "DevOps Engineer",
    image: farhanImage, // Replace with the image URL for team member 2
  },
  {
    name: "Muhammad Hasan",
    designation: "Business Developer",
    image: hasanImage, // Replace with the image URL for team member 1
  },
  {
    name: "Haris Ali",
    designation: "Web Developer",
    image: harisImage, // Replace with the image URL for team member 1
  },
  {
    name: "Hafiz Ahmad",
    designation: "Marketing Head",
    image: ahmadakbarImage, // Replace with the image URL for team member 2
  },
  {
    name: "Abdul Rehman",
    designation: "Animator",
    image: abdulRehmanImage, // Replace with the image URL for team member 2
  },
  {
    name: "Anser Shafiq",
    designation: "Script Writer",
    image: anserImage, // Replace with the image URL for team member 1
  },
  {
    name: "Abdullah Khalid",
    designation: "Storyboard Designer",
    image: abImage, // Replace with the image URL for team member 1
  },
];

const About = () => {
  const [startIndex, setStartIndex] = React.useState(0);

  // Function to handle scrolling to the next set of team members
  const scrollNext = () => {
    const newIndex = Math.min(startIndex + 4, teamMembers.length - 1);
    setStartIndex(newIndex);
  };

  // Function to handle scrolling to the previous set of team members
  const scrollPrev = () => {
    const newIndex = Math.max(startIndex - 4, 0);
    setStartIndex(newIndex);
  };

  return (
    <div>
      <section
        className="about-intro"
        style={{
          paddingBottom: "2%",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
                       rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        }}
      >
        <div className="container">
          <h1
            className="text-white text-center mb-4"
            style={{
              padding: "2%",
            }}
          >
            We deliver some of the best quality animated content to our clients!
          </h1>
          <div className="video-collage">
            <div className="row">
              <div className="col-md-6 mb-3">
                <video controls width="75%" height="auto">
                  <source
                    src="https://drive.google.com/uc?id=1bEKK4o-Ij-el2EjHLsuzJliu8LykAPy5"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="col-md-6 mb-3">
                <video controls width="75%" height="auto">
                  <source
                    src="https://drive.google.com/uc?id=1O8wqDOjsRso6gGSGDMcq-bdUU7Ea8ZzA"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="col-md-6 mb-3">
                <video controls width="75%" height="auto">
                  <source
                    src="https://drive.google.com/uc?id=1-Hl6BpceA8ndrDL_RV6M34eubX9pPvj8"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="col-md-6 mb-3">
                <video controls width="75%" height="auto">
                  <source
                    src="https://drive.google.com/uc?id=1-Hl6BpceA8ndrDL_RV6M34eubX9pPvj8"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team Members Section */}
      <section className="team-members">
        <div className="container">
          <h2
            className="text-center mb-4"
            style={{
              paddingTop: "1%",
            }}
          >
            Our team of experts and professionals that work as a family.
          </h2>
          <div className="row">
            {teamMembers
              .slice(startIndex, startIndex + 4) // Display 4 members at a time
              .map((member, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <Card
                    className="team-item mt-3" // Fixed typo in 'className'
                    style={{
                      color: "#3D0037",
                      backgroundColor: "#9e66be",
                      width: "14rem",
                      borderRadius: "10%",
                      border: "3px solid #6D2877",
                      boxShadow: "4px 8px 12px rgb(160, 81, 101)",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      style={{
                        borderRadius: "11% 11% 0% 0%",
                      }}
                      src={member.image}
                      alt={member.name}
                    />
                    <Card.Body>
                      <Card.Title>{member.name}</Card.Title>
                      <Card.Text>{member.designation}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
          <div className="text-center mt-3">
            <div className="mx-auto">
              <ButtonToolbar
                className="mb-3"
                aria-label="Toolbar with Button groups"
              >
                <div className="text-center mx-auto mb-4">
                  <ButtonGroup className="me-2" aria-label="First group">
                    {startIndex > 0 && (
                      <Button variant="outline-light" onClick={scrollPrev}>
                        <FaArrowLeft /> Previous
                      </Button>
                    )}
                    {startIndex + 4 < teamMembers.length && (
                      <Button variant="outline-light" onClick={scrollNext}>
                        Next <FaArrowRight />
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </section>

      <section className="about-details">
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-md-6">
              <div className="founder">
                <img
                  src={founderImage}
                  alt="Founder"
                  className="img-fluid rounded-circle"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="mb-0">Message From Our CEO</h2>
              <div className="ceo-message">
                <p className="ceo-quote">
                  {
                    " Our mission is to simplify complexity and captivate audiences through the art of animated storytelling. Our team's talent and dedication make this possible. Let's keep crafting compelling narratives that leave a lasting impact."
                  }
                </p>
                <p className="ceo-name">Hasan Talat</p>
                <p className="ceo-title">CEO, Faux Film Studios</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
