import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer custom-footer-bg text-white text-center py-2">
      <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
        <p className="mb-0">
          © {new Date().getFullYear()} FauxFilmStudios. All Rights Reserved.
        </p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/FauxFilmStudios"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="icon" />
          </a>
          <a
            href="https://instagram.com/faux_film_studios"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="icon" />
          </a>
          <a
            href="https://youtube.com/@fauxfilmstudios7902"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
