import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import contactUsPage from "../Assets/contact-us.jpeg";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_nnbq2sw",
        "template_cecl4t4",
        form.current,
        "KnEj5jDhHNQpl3ZGm"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
                       rgba(0, 0, 0, 0.5)), url(${contactUsPage})`,
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed", // Optional, for a fixed background
        minHeight: "100vh",
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="container mt-5">
        <h1 className="contact-us-text">Contact Us</h1>
        <p className="contact-us-text">
          If you have any questions or inquiries, feel free to reach out to us.
        </p>
        <div className="row">
          <div className="col-md-6">
            <div className="social-media-links">
              <h2 className="contact-us-text">Follow us on Social Media!</h2>
              <div className="social-icons-grid">
                <a
                  href="https://www.facebook.com/FauxFilmStudios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} size="5x" />
                </a>
                <a
                  href="https://instagram.com/faux_film_studios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="5x"
                    className="instagram-icon"
                  />
                </a>
                <a
                  href="https://youtube.com/@fauxfilmstudios7902"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="5x"
                    className="youtube-icon"
                  />
                </a>
              </div>
            </div>
            <h2 className="contact-us-text">Contact Information</h2>
            <b style={{ fontSize: "18px" }}>
              <p className="form-label">
                Email:{" "}
                <span className="form-label-highlited">
                  info@fauxfilmstudios.tech
                </span>
              </p>
            </b>
            <b style={{ fontSize: "18px" }}>
              <p className="form-label">
                Phone:{" "}
                <span className="form-label-highlited">(+92) 332 8446830</span>
              </p>
            </b>
          </div>
          <div className="col-md-6">
            <h2 className="contact-us-text">Send Us a Message</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className="form-label"
                      style={{ fontSize: "18px" }}
                    >
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_name"
                      name="user_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ fontSize: "18px" }}
                    >
                      <b>Email</b>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="user_email"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="subject"
                  className="form-label mt-2"
                  style={{ fontSize: "18px" }}
                >
                  <b>Subject</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="message"
                  className="form-label mt-2"
                  style={{ fontSize: "18px" }}
                >
                  <b>Message</b>
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                ></textarea>
              </div>
              <Button variant="outline-light" size="lg" className="mt-4">
                Send Message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
