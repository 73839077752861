// src/pages/Home.js
import React from "react";
import logo from "../Assets/logo.png"; // Replace with your logo image path
import Welcome from "../Assets/welcome2.jpg";
import { AiFillStar, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlinePeople } from "react-icons/md";

const Home = () => {
  const record = [
    {
      icon: () => <MdOutlinePeople size={55} fill="#5C006B" />,
      title: "200+",
      description: "Satisfied Clients",
    },
    {
      icon: () => <AiOutlineFundProjectionScreen size={55} fill="#5C006B" />,
      title: "750+",
      description: "Delivered Projects",
    },
    {
      icon: () => <AiFillStar size={55} fill="#5C006B" />,
      title: "7+",
      description: "Years of Experience",
    },
  ];

  return (
    <div className="home-box justify-content-center align-items-center">
      <h1 className="form-label" style={{ color: "black", marginTop: "4%" }}>
        Welcome to Faux Film Studios
      </h1>
      <p className="form-label mb-3" style={{ color: "#5C006B" }}>
        Your premier destination for digital animation and ads animations.
      </p>
      <div className="container d-flex">
        <div className="row">
          <div className="col-md-5">
            <div className="text-center">
              <img src={logo} alt="Logo" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex mt-3">
              {record.map((item) => (
                <div className="mt-3 mb-2" style={{ marginRight: "20%" }}>
                  {item.icon()}
                  <h3 className="form-label" style={{ color: "black" }}>
                    {item.title}
                  </h3>
                  <p>
                    <b style={{ color: "#5C006B" }}>{item.description}</b>
                  </p>
                </div>
              ))}
            </div>
            <div>
              <img
                src={Welcome}
                alt="Logo"
                className="mt-4 img-fluid welcome-pic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
